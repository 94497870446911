@import url("https://use.typekit.net/fpp3tou.css");

html {
  --bodyFontColor: black;
  --border: 2px solid #000000;
  --borderHover: 2px solid #222222;
  --buttonBorderRadius: 40px;
  --buttonFontSize: 20px;
  --buttonFontWeight: bold;
  --buttonHoverColor: white;
  --buttonHoverFontColor: #222222;
  --buttonTextTransform: none;
  --fontType: "brandon-grotesque";
  --headerAlignment: right;
  --headerBackgroundColor: #222222;
  --headerIconHeight: 18px;
  --headerMarginTop: 20px;
  --primaryButtonColor: #222222;
  --primaryButtonFontColor: white;
  --titlesFontColor: black;
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--titlesFontColor);
  font-family: var(--fontType, Arial, Helvetica, sans-serif);
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 812px;

  /* override bootstrap styles */
  padding-left: 12px !important;
  padding-right: 12px !important;
}

#header {
  width: 100%;
  background-color: var(--headerBackgroundColor);
  position: fixed;
  z-index: 1;
  text-align: var(--headerAlignment);
}

#header img {
  height: var(--headerIconHeight);
  margin-top: var(--headerMarginTop);
}

#footer {
  margin-top: 10px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  background-color: white;
}

#footer img {
  height: 28px;
  width: 28px;
  margin-left: 4px;
}

.shadowTop {
  -webkit-box-shadow: 0px -2px 3px #d3d3d3;
  -moz-box-shadow: 0px -2px 3px #d3d3d3;
  box-shadow: 0px -2px 3px #d3d3d3;
}

.inputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputFile + label,
.submitButton,
input[type="submit"] {
  background-color: var(--primaryButtonColor);
  border: 2px solid var(--primaryButtonColor);
  color: var(--primaryButtonFontColor);
  border-radius: var(--buttonBorderRadius);
  text-transform: var(--buttonTextTransform);
  font-size: var(--buttonFontSize);
  font-family: var(--fontType, Arial, Helvetica, sans-serif);
  font-weight: var(--buttonFontWeight);
  border: var(--border);

  padding: 8px 0;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.submitButtonInverted {
  background-color: var(--buttonHoverColor);
  border: 2px solid #000000;
  color: var(--buttonHoverFontColor);
  border-radius: var(--buttonBorderRadius);
  text-transform: var(--buttonTextTransform);
  font-family: var(--fontType, Arial, Helvetica, sans-serif);
  font-weight: var(--buttonFontWeight);

  padding: 8px 0;
  text-align: center;
  text-decoration: none;
  display: block;
  letter-spacing: 0.1px;
  line-height: 24px;
  width: 100%;
}

#emailForm input[type="submit"] {
  background-color: var(--primaryButtonColor);
  border: 2px solid var(--primaryButtonColor);
  color: var(--primaryButtonFontColor);
  border-radius: var(--buttonBorderRadius);
  text-transform: var(--buttonTextTransform);
  font-size: var(--buttonFontSize);
  font-family: var(--fontType, Arial, Helvetica, sans-serif);
  font-weight: var(--buttonFontWeight);

  padding: 8px 0;
  text-align: center;
  width: 100%;
  text-decoration: none;
  display: block;
  letter-spacing: 0.1px;
  line-height: 24px;
}

#emailForm input[type="submit"]:hover {
  background-color: var(--buttonHoverColor);
  color: var(--buttonHoverFontColor);
  border: 2px solid #000000;
}

.submitButtonInverted:hover {
  background-color: var(--primaryButtonColor);
  border: 2px solid var(--primaryButtonColor);
  color: var(--primaryButtonFontColor);
}

#emailForm .submitButton {
  width: 100%;
}

@media (max-width: 768px) {
  .bikeMatchButton {
    width: 46vw;
    margin-left: auto;
    margin-right: auto;
  }
}

#footer a,
.resultsButton a,
.resultsButton a:hover {
  text-decoration: none;
  color: black;
}

.noUnderline a:hover {
  text-decoration: none;
}

.externalLinkButtonInverted a:hover {
  color: white;
}

.dualButtonsDivider {
  margin-top: 24px;
}

.underFormButtonWrapper {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
}

.inputFile:focus + label,
.inputFile + label:hover {
  background-color: var(--buttonHoverColor);
  color: var(--buttonHoverFontColor);
  border: 2px solid #000000;
  border: var(--borderHover);
}

.footerButtonWrapper {
  padding-top: 12px;
  align-items: center;
  padding-left: 3.5%;
  padding-right: 3.5%;
}

.dualButtonsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dualButtonsWrapper .submitButton {
  width: 46vw;
  margin-left: auto;
  margin-right: auto;
}

.dualButtonsWrapper .photoButton {
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
}

.dualButtonsWrapper a {
  margin-left: auto;
  margin-right: auto;
}

.dualButtonsWrapper .inputFile + label {
  width: 44vw;
  background-color: var(--buttonHoverColor);
  color: var(--buttonHoverFontColor);
  border: 2px solid #000000;
  border-radius: var(--buttonBorderRadius);
}

.dualButtonsWrapper .inputFile + label:hover,
.dualButtonsWrapper .inputFile + label:focus {
  background-color: var(--primaryButtonColor);
  border-color: var(--primaryButtonColor);
  color: var(--primaryButtonFontColor);
}

.submitButton:hover,
.submitButton:focus,
input[type="submit"]:hover {
  background-color: var(--buttonHoverColor);
  color: var(--buttonHoverFontColor);
  border: 2px solid #000000;
  border: var(--borderHover);
}

.footerTagline {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 110px;
  width: 100%;
}

.title {
  font-size: 25px;
  letter-spacing: 0;
  line-height: 36px;
  width: 100%;
}

.titleContainer {
  width: 100%;
  padding: 12px 0 24px;
}

.smallTitleContainer {
  text-align: left;
  width: 100%;
  line-height: 26px;
  padding-left: 22px;
  padding-top: 8px;
}

.titleContainerLessPadding {
  padding: 12px 10px;
  width: 100%;
}

.titleContainerNoBackButton {
  padding: 12px 0 24px;
}

.headlineAndButtonContainer {
  width: 100%;
  display: inline-flex;
}

.headlineAndButtonContainer [type="button"] {
  align-items: center;
}

.subTitle {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  width: 100%;
  padding-top: 8px;
  color: var(--bodyFontColor);
}

button .subTitle {
  padding-top: 4px;
  padding-left: 11px;
  width: unset;
}

.backbutton {
  background-color: transparent;
  border-width: 0;
  padding: 0;
  display: contents;
}

.backbuttonimg {
  height: 30px;
  width: 30px;
}

.fullWidthImage {
  width: 100%;
}

.bikeNameAndImageContainer {
  width: 100%;
  margin-top: 8px;
}

.bikeResultImage {
  max-height: 200px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.bikeName {
  font-size: 32px;
  line-height: 32px;
  margin-top: 15px;
  font-weight: 300;
  text-align: left;
}

.headerdiv {
  height: 30px;
  width: 30px;
}

.takepicturediv {
  height: 80vh;
  align-items: flex-end;
  display: flex;
}

.photoimg {
  border: 1px solid white;
  height: 300px;
  width: 95vw;
  object-fit: contain;
}

.dropdowns {
  width: 100%;
  text-align: left;
  margin-top: 7px;
}

.dropdowns ul {
  padding-inline-start: 0;
}

.dropdowns h2 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0;
}

.dropdownItem {
  border-bottom: 1px solid #eaeaea;
  align-items: baseline;
  padding-bottom: 16px;
  padding-top: 16px;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

.checkmark {
  padding-right: 12px;
}

.card-body {
  max-width: 560px;
  padding: 1.25rem 0;
}

/* override bootstrap styles */
#accordion .card {
  border: none;
}

.card-header {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  display: flex;
}

#accordion .card .card-header {
  background-color: inherit;
  margin-bottom: 0;
  border-bottom: 1px solid #eaeaea;
  padding: 16px 0;
  background-color: inherit;
}

#accordion .card-body {
  color: var(--bodyFontColor);
}

/* end override */

.cardOptionsList ul {
  padding-inline-start: 0;
}

.cardOptionsList ul li::before {
  content: url("assets/green_check.svg");
  font-size: 1em;
}

.cardOptionsList ul li.badCard::before {
  content: url("assets/red_x.svg");
}

.bikeResultsList ul li::before {
  content: url("assets/checkmark.svg");
}

.cardOptionsList li,
.bikeResultsList li {
  color: var(--bodyFontColor);
  display: grid;
  grid-template-columns: 0 1fr;
  grid-gap: 1.5em;
  align-items: start;
}

.bikeResultsList li {
  margin-bottom: 12px;
}

.cardOptionsList li {
  margin-bottom: 8px;
}

.bikeResultsList {
  margin-top: 24px;
}

.dropdownTextWrapper {
  display: flex;
  align-items: center;
}

.dropdownExpanded {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 12px;
  margin-top: 12px;
  max-width: 560px;
}

.textLeftWrapper {
  text-align: left;
  width: 100%;
}

.bodyText {
  font-size: 18px;
  line-height: 26px;
}

.viewBikeName {
  font-size: 22px;
  text-align: left;
}

.whatCard {
  text-align: left;
  margin-bottom: 12px;
  margin-top: 24px;
}

.reviewPictureList {
  margin-top: 8px;
}

.secondHalfContentWrapper {
  margin-top: 24px;
  text-align: left;
}

.viewBikeContentWrapper {
  text-align: left;
  margin-top: 20px;
}

.whyCard {
  margin-top: 24px;
  margin-bottom: 24px;
}

.errorCard {
  margin-top: 12px;
  margin-bottom: 24px;
}

.whyQuestionWrapper {
  margin-bottom: 12px;
}

.errorQuestionWrapper {
  margin-bottom: 12px;
  display: flex;
}

.sideBySideListWrapper {
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 768px) {
  .sideBySideListWrapper {
    display: inline-flex;
  }

  .sideBySideListWrapper ul {
    margin-right: 40px;
  }
}

.caretIcon.open {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transform: rotate(180deg);
}

.photoInstructionsImage {
  max-height: 315px;
  width: auto;
}

.errorMessageContainer {
  border: 1px solid #ea0713;
  border-radius: 6px;
}

.errorMessageTextWrapper {
  padding: 12px;
}

.errorIcon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.caretIcon {
  background: url("assets/caret.svg") no-repeat center;
  background-size: 18px 18px;
  height: 24px;
  width: 24px;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}

.caretBack {
  background: url("assets/caret.svg") no-repeat center;
  background-size: 20px 20px;
  height: 24px;
  width: 24px;
  margin-top: 5px;
  transform: rotate(90deg);
}

.dropdowns .caretIcon {
  margin-left: auto;
}

.resultsContainer {
  margin-bottom: 24px;
  width: 100%;
}

.resultsTagline {
  text-align: left;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #eaeaea;
  font-size: 18px;
}

.resultsNameAndPrice {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 20px;
}

.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #f8f8f8ad;
}

.loadingIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  position: absolute;
}

/* override bootstrap styles */
#emailForm .modal-body {
  padding-top: 0;
  padding: 0 0 1rem;
  padding-bottom: 1rem;
}

#emailForm .modal-header {
  border-bottom: none !important;
  padding: 28px 1rem 0;
}

#emailSent .modal-header {
  border-bottom: none;
}

#emailForm .modal-header .close:after,
#emailSent .modal-header .close:after {
  content: url("assets/close_x.svg");
}

#emailForm .modal-header .close span,
#emailSent .modal-header .close span {
  display: none;
}

#emailForm .modal-title,
#emailSent .modal-title {
  margin: 0 12px 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  font-family: "brandon-grotesque", Arial, Helvetica, sans-serif;
  max-width: 72%;
}

#emailForm input[type="submit"] {
  margin-top: 24px;
}

#emailForm .form-control {
  background-color: rgba(9, 19, 33, 0.06);
}

/* end override */

form {
  margin: 0px 12px 0px 12px;
  font-family: "brandon-grotesque", Arial, Helvetica, sans-serif;
}

form label {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  display: block;
  margin-bottom: 5px;
  margin-right: 10px;
  text-align: left;
  margin-left: 1%;
}

form input[type="text"] {
  width: 100%;
  border: none;
  font-size: 36px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: rgba(9, 19, 33, 0.06);
  outline: 0;
  padding: 0 12px;
}

.emailSentWrapper {
  margin-top: 57px;
  margin-bottom: 24px;
  text-align: center;
}

.emailResultsTextWrapper {
  margin-bottom: 57px;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
}

.emailResultsEmojiWrapper {
  font-size: 36px;
  margin-bottom: 36px;
}

.bikePriceContainer {
  font-size: 24px;
  font-weight: bold;
  line-height: 46px;
}

.bikeTagline {
  font-weight: bold;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 11.5px;
}

.passwordFeedback {
  color: red;
}

/* Desktop overrides */
@media (min-width: 768px) {
  .titleContainer {
    margin-top: 24px;
    width: 100%;
  }

  .content {
    max-width: 540px;
  }

  .headlineAndButtonContainer {
    width: 100%;
  }

  .titleContainerNoBackButton {
    padding: 12px 24px 24px;
  }

  .backbutton {
    position: inherit;
  }

  .inputFile + label,
  .submitButton,
  input[type="submit"],
  .submitButtonInverted,
  #emailForm input[type="submit"],
  #emailForm .submitButton {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .dualButtonsWrapper .submitButton {
    margin-left: 6px;
    width: 300px;
  }

  .dualButtonsWrapper .photoButton {
    margin-right: 6px;
  }

  .dualButtonsWrapper .inputFile + label {
    width: 300px;
  }
}

.demoContainer {
  display: flex;
  flex-direction: column;
  padding-top: 45px;
  padding-bottom: 160px;
  width: 100%;
}

.demoFormItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 12;
  width: 100%;
}

.demoResultsContainer {
  margin-top: 1.5rem;
}

.demoFormSelect {
  width: 100%;
  background-color: white;
  border: 2px solid black;
  border: var(--border);
  color: black;
  border-radius: var(--buttonBorderRadius);
  text-transform: var(--buttonTextTransform);
  font-size: var(--buttonFontSize);
  font-family: var(--fontType, Arial, Helvetica, sans-serif);
  font-weight: var(--buttonFontWeight);

  padding: 8px;
  text-decoration: none;
  display: block;
  width: 100%;
  letter-spacing: 0.1px;
  line-height: 24px;
}
